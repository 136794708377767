<template>
	<div>
		<div class="card">
			<router-link :to="{name: 'vehicles', params : {lang: path, pack_name : list.pack_route}}" v-if="!showButton"
						 @click.native="scrollToTop();emitChange()">
				<img class="card-img-top"
					 :src="setImg(list.img_src)"
					 alt="">
			</router-link>
			<a v-if="showButton" style="display: block; cursor:pointer;"
			   @click="scrollToTop();goToRoute(list.pack_route)">
				<img class="card-img-top"
					 :src="setImg(list.img_src)"
					 alt="">
			</a>
			<div class="card-body text-center">
                <span class="card-pack-name">
                    {{ $t('message.pack_name', { 'pack' : $t(`message.pack[0].${list.pack_name}`) }) }}</span>
				<p class="car-title">
					{{ list.car_name }}
				</p>
				<p class="car-price">
					<span>{{ $t("message.pack_since") }} </span>{{list.price}}€<span class="dia">/{{ $t("message.pack_day") }}</span>
				</p>
				<h5 class="card-title mt-2 mb-2">
					<span class="badge badge-primary mr-1">
                    {{ $t('message.drivetrain', { 'dtrain' : $t(`message.dtrain[0].${list.drivetrain}`) }) }}</span>
					<span class="badge badge-primary">
                    {{ $t('message.fuel', { 'fueltype' : $t(`message.fueltype[0].${list.fuel}`) }) }}</span>
				</h5>
				<router-link :to="{name: 'vehicles', params : {lang: path, pack_name : list.pack_route}}"
							 v-if="!showButton"
							 class="btn btn-primary mt-2" @click.native="scrollToTop();emitChange()">
					{{ $t('message.ask_rate') }}
				</router-link>
				<button v-if="showButton"
						class="btn btn-primary mt-2"
						@click="scrollToTop();goToRoute(list.pack_route)">
					{{ $t('message.pre_reservation') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name       : 'Card',
	components : {},
	props      : {
		list : {
			type     : Object,
			required : true,
		}
	},
	data       : function () {
		return {
			showButton : this.$route.name === 'rent' && !!Object.keys(this.$route.query).length,
			query      : this.$route.query
		};
	},
	computed   : {
		path() {
			return this.$store.getters.setLocale;
		}
	},
	methods    : {
		goToRoute(pack) {
			this.query.pack = pack;
			this.$router.push({
				path  : `/${this.$route.params.lang}/pre-reservation`,
				query : this.query
			}).catch(err => {
			});
		},
		emitChange() {
			this.$emit('carouselreload', true);
		},
		scrollToTop() {
			window.scroll({
				top      : 0,
				left     : 0,
				behavior : 'smooth'
			});
		},
		setImg(val) {
			// let img = val.split(/\.(?=[^.]+$)/);

			let screen = window.innerWidth < 768 ? 'mobile' : 'desktop';
			return `https://vault13.rotauto.com/sites/Lubrirent/cars/${val}`;
		},
	}
};
</script>
